import {AnyAction} from 'redux'
import {SEND_RSVP} from '../actions/rsvp'

export const guest = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case SEND_RSVP.SUCCESS:
      return action.payload.rsvp
    default:
      return state
  }
}
