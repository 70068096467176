import {createAction} from '@reduxjs/toolkit'

export const ADD_TO_CALENDAR_CLICKED = 'ADD_TO_CALENDAR_CLICKED'

export const addToCalendarClicked = () => ({type: ADD_TO_CALENDAR_CLICKED})

export const addToCalendarOptionClicked = createAction<AddToCalendarOptionClickedParams>(
  'ADD_TO_CALENDAR_OPTION_CLICKED',
)

export interface AddToCalendarOptionClickedParams {
  origin: string
  sourcePage: string
}
