import {EventStatus, EventType, LocationType, RegistrationStatus} from '@wix/events-types'
import {addQueryParams} from '@wix/panda-js-utils'
import {
  EventsDates,
  getAboutText,
  isDateTbd,
  isEndDateHidden,
  isEventLocationOnline,
  isLocationTbd,
  isRecurringEvent,
} from '@wix/wix-events-commons-statics'

export const transformEvent = (
  event: wix.events.Event,
  pageUrl: string,
  language: string | undefined,
  dates: EventsDates,
) => ({
  ...event,
  about: getAboutText(event),
  status: EventStatus[event.status],
  eventPageUrl: {
    base: pageUrl,
    path: addQueryParams(`/${event.slug}`, {lang: language}),
  },
  registration: {
    ...event.registration,
    type: EventType[event.registration.type],
    status: RegistrationStatus[event.registration.status],
  },
  location: {
    ...event.location,
    type: isLocationTbd(event) ? undefined : LocationType[event.location.type],
  },
  scheduling: {
    ...event.scheduling,
    config: {
      ...event.scheduling.config,
      startDate: isDateTbd(event) ? undefined : dates[event.id].startDateISOFormatNotUTC,
      endDate: isEndDateHidden(event) ? undefined : dates[event.id].endDateISOFormatNotUTC,
    },
  },
  ...(event.onlineConferencing
    ? {
        onlineConferencing: {
          ...event.onlineConferencing,
          ...(isEventLocationOnline(event) && isRecurringEvent(event)
            ? {config: {...event.onlineConferencing.config, enabled: true}}
            : null),
        },
      }
    : null),
})
