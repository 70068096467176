import {AnyAction} from 'redux'
import {DemoEvents} from '../types/state'

const defaultState: DemoEvents = {} as DemoEvents

export const demoEvents = (state = defaultState, action: AnyAction): DemoEvents => {
  switch (action.type) {
    default:
      return state
  }
}
