export const TICKETS_PICKER_EXPAND_DESCRIPTION = 'TICKETS_PICKER_EXPAND_DESCRIPTION'
export const TICKETS_PICKER_COLLAPSE_DESCRIPTION = 'TICKETS_PICKER_COLLAPSE_DESCRIPTION'

export const collapseDescription = (ticketDefId: string) => ({
  type: TICKETS_PICKER_COLLAPSE_DESCRIPTION,
  payload: ticketDefId,
})

export const expandDescription = (ticketDefId: string) => ({
  type: TICKETS_PICKER_EXPAND_DESCRIPTION,
  payload: ticketDefId,
})
