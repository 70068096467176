import {createSlice} from '@reduxjs/toolkit'
import {describeSeatingPlan} from '../../actions/seating/plan'

const initialState = null

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(describeSeatingPlan.fulfilled, (_state, action) => action.payload.plan)
  },
})

export default planSlice.reducer
