import {createAction} from '@reduxjs/toolkit'
import {Place} from '@wix/ambassador-seating-v1-seating-plan/types'
import {
  ExperimentNames,
  PlaceWithTicketInfo,
  getPricingOptions,
  hasPricingOptions,
  hasSinglePricingOption,
  saleScheduled,
} from '@wix/wix-events-commons-statics'
import {isMobile} from '../../../../../commons/selectors/environment'
import {SEATING_ERROR} from '../../constants'
import {setSeatingError} from '../../reducers/seating/errors'
import {
  selectPlace,
  setShowPlaceDescription,
  setShowPricingOptionsPlace,
  unselectPlace,
} from '../../reducers/seating/mode'
import {updatePlace} from '../../reducers/seating/places'
import {isChooseSeatMode} from '../../selectors/navigation'
import {isInAccessibilityMode, isInDescription, isInMobileBasket} from '../../selectors/seating/mode'
import {
  getTotalPlaceQuantity,
  isPlaceArea,
  isPlaceInBasket,
  isPlaceReservableAsWhole,
} from '../../selectors/seating/place'
import {
  getAnyPlaceById,
  getAreaCounterValue,
  getPlace,
  getPlaceById,
  getPlaces,
  getSelectedPlace,
  isAreaPriceOptionPlaceId,
} from '../../selectors/seating/places'
import {createAsyncAction} from '../../services/redux-toolkit'
import {validateDonation, validateTicketLimit} from './validation'

interface SelectPlacePricingOption {
  placeId: string
  pricingOptionIds: string[]
  origin: string
}

export const selectPlacePricingOption = createAsyncAction<void, SelectPlacePricingOption>(
  'SELECT_PLACE_PRICING_OPTION',
  async ({placeId, pricingOptionIds, origin}, {getState, dispatch}) => {
    const state = getState()
    const place = getPlace(state, placeId)
    const mobile = isMobile(state)

    let selectedPricingOptionIds = place.selectedPricingOptionIds

    if (isPlaceArea(place)) {
      selectedPricingOptionIds = [...(selectedPricingOptionIds || []), ...pricingOptionIds]
      dispatch(setAreaCounterValue({placeId: place.id, count: selectedPricingOptionIds.length}))
    } else {
      selectedPricingOptionIds = pricingOptionIds
    }

    dispatch(
      updatePlace({
        place: {
          id: placeId,
          quantity: selectedPricingOptionIds.length,
          selectedPricingOptionIds,
        },
        origin,
        clearOthers: isChooseSeatMode(state),
      }),
    )

    if (selectedPricingOptionIds.length) {
      dispatch(setShowPlaceDescription(null))
    }

    if (mobile) {
      dispatch(unselectPlace())
    }
  },
)

interface SetPlaceQuantityArgs {
  placeId: string
  count: number
  origin: string
  validateDonation?: boolean
}

export const setPlaceQuantity = createAsyncAction<void, SetPlaceQuantityArgs>(
  'SET_PLACE_QUANTITY',
  async (
    {placeId, count, validateDonation: shouldValidateDonation = true, origin},
    {getState, dispatch, rejectWithValue, extra: {flowAPI}},
  ) => {
    const state = getState()
    const place = getPlace(getState(), placeId)
    const {ticket, donation} = place
    const chooseSeatMode = isChooseSeatMode(state)
    const inBasket = isPlaceInBasket(place)
    const selectedTicketsQuantity = getTotalPlaceQuantity(getPlaces(state), place.planPlaceId)

    const nextTicketCount = selectedTicketsQuantity + count
    const capacityExceeded = place.capacity - nextTicketCount < 0

    const planTicketsDesignEnabled = flowAPI.experiments.enabled(ExperimentNames.PlanTicketsDesign)

    if (planTicketsDesignEnabled && !inBasket && capacityExceeded) {
      dispatch(setSeatingError({error: SEATING_ERROR.PLACE_CAPACITY_REACHED, planPlaceId: place.planPlaceId}))
      return
    }

    if (count > 0 && shouldValidateDonation) {
      const valid = await dispatch(validateDonation({placeId, ticket, donation})).unwrap()

      if (!valid) {
        return rejectWithValue({error: 'Invalid donation'})
      }
    }

    if (count > 0 && isPlaceReservableAsWhole(place)) {
      count = place.places.length
    }

    let updatePlaceArg: Parameters<typeof updatePlace>[0] = {
      place: {
        id: placeId,
        quantity: count,
      },
      clearOthers: chooseSeatMode,
      origin,
    }

    if (count > 0 && hasSinglePricingOption(ticket)) {
      const pricingOptions = getPricingOptions(ticket)
      updatePlaceArg = {
        ...updatePlaceArg,
        place: {
          ...updatePlaceArg.place,
          selectedPricingOptionIds: Array(count).fill(pricingOptions[0].id),
        },
      }
    }

    dispatch(updatePlace(updatePlaceArg))
  },
)

export const addPlaceDonation = createAsyncAction<void, {placeId: string; donation: string}>(
  'ADD_PLACE_DONATION',
  async ({placeId, donation}, {getState, dispatch}) => {
    dispatch(
      updatePlace({
        place: {
          id: placeId,
          donation,
        },
      }),
    )

    if (isInDescription(getState())) {
      const place = getPlaceById(getPlaces(getState()), placeId)
      dispatch(setShowPlaceDescription(place))
    }
  },
)

export const clickPlace = createAsyncAction<void, {place?: Place; origin: string; updateShownDescription?: boolean}>(
  'CLICK_PLACE',
  async ({place, origin, updateShownDescription}, {getState, dispatch, extra: {flowAPI}}) => {
    const state = getState()
    const planTicketsDesignEnabled = flowAPI.experiments.enabled(ExperimentNames.PlanTicketsDesign)

    if (!place) {
      return
    }

    const showAccessibilityMode = isInAccessibilityMode(state)
    const inDescription = isInDescription(state)
    const mobile = isMobile(state)
    const desktop = !mobile
    const selectedPlace = getSelectedPlace(state)
    const currentCount = getTotalPlaceQuantity(getPlaces(state), place.id)
    const area = isPlaceArea(place)

    const hasAreaPriceOptionId = isAreaPriceOptionPlaceId(getPlaces(state), place.id)
    const placeWithInfo = getAnyPlaceById(getPlaces(state), place.id)
    const ticket = placeWithInfo.ticket
    const areaCounterValue = getAreaCounterValue(state, place.id)
    const inBasket = isPlaceInBasket(placeWithInfo)
    const pricingOptions = hasPricingOptions(ticket)
    const scheduled = saleScheduled(ticket)
    const singlePricingOption = hasSinglePricingOption(ticket)
    const mobileBasketView = isInMobileBasket(state)

    const valid = await dispatch(validateTicketLimit({planPlaceId: place.id})).unwrap()

    if (!valid) {
      return
    }

    const handlePlaceSelectUnselect = () => {
      const shouldShowPricingOptions = (planTicketsDesignEnabled && desktop) || !planTicketsDesignEnabled

      if (shouldShowPricingOptions && pricingOptions && !scheduled && !singlePricingOption) {
        dispatch(setShowPricingOptionsPlace(placeWithInfo))

        if (selectedPlace?.id) {
          dispatch(unselectPlace())
        }

        return
      }

      selectedPlace?.id === placeWithInfo.id ? dispatch(unselectPlace()) : dispatch(selectPlace(placeWithInfo.id))
    }

    const handleAddPlaceToBasket = () => {
      if (scheduled) {
        return
      }

      let placeId = place.id

      const mobileMapAreaPricingOptions =
        planTicketsDesignEnabled && mobile && !mobileBasketView && !showAccessibilityMode && area && pricingOptions

      if (pricingOptions && (!currentCount || area) && (!hasAreaPriceOptionId || mobileMapAreaPricingOptions)) {
        if (singlePricingOption) {
          if (area) {
            placeId = placeWithInfo.id
          }
        } else {
          dispatch(setShowPricingOptionsPlace(placeWithInfo))
          return
        }
      }

      let count: number

      if (area) {
        if (planTicketsDesignEnabled) {
          if (inBasket) {
            count = 0
            dispatch(setAreaCounterValue({placeId: place.id, count: 1}))
          } else {
            count = areaCounterValue ?? 1
          }
        } else {
          count = currentCount + 1
        }
      } else {
        count = Number(!currentCount)
      }

      dispatch(
        setPlaceQuantity({
          placeId,
          count,
          origin,
          validateDonation: !planTicketsDesignEnabled && showAccessibilityMode,
        }),
      )

      if (planTicketsDesignEnabled && updateShownDescription && !count) {
        dispatch(
          setShowPlaceDescription({
            ...placeWithInfo,
            quantity: count,
            selectedPricingOptionIds: [],
            donation: undefined,
          }),
        )
      } else {
        dispatch(setShowPlaceDescription(null))
      }
    }

    if (desktop || showAccessibilityMode || inDescription || (currentCount && !area)) {
      handleAddPlaceToBasket()
    } else {
      handlePlaceSelectUnselect()
    }
  },
)

export const setAreaCounterValue = createAction<{placeId: string; count: number}>('SET_AREA_COUNTER_VALUE')

export const resetAreaCounterValue = createAction<{
  place: PlaceWithTicketInfo
  keepQuantityIfNeeded?: boolean
  strictId?: string
}>('RESET_AREA_COUNTER_VALUE')
