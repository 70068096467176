import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {checkout} from '../../actions/checkout'
import {SEATING_ERROR} from '../../constants'
import {DONATION_ERROR, SeatingErrors} from '../../types'
import {updatePlace} from './places'

const initialState: SeatingErrors = {
  error: null,
  planPlaceId: null,
  donationErrors: {},
}

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setSeatingError(state, action: PayloadAction<{error: SEATING_ERROR; planPlaceId?: string}>) {
      state.error = action.payload?.error ?? null
      state.planPlaceId = action.payload?.planPlaceId ?? null
    },
    setPlaceDonationError(state, action: PayloadAction<{placeId: string; error: DONATION_ERROR}>) {
      state.donationErrors[action.payload.placeId] = action.payload.error
    },
    setPlaceDonationErrors(state, action: PayloadAction<{placeId: string; error: DONATION_ERROR}[]>) {
      state.donationErrors = action.payload.reduce((errors, {placeId, error}) => {
        errors[placeId] = error
        return errors
      }, {})
    },
  },
  extraReducers: builder => {
    builder
      .addCase(checkout.fulfilled, state => {
        state.error = null
      })
      .addCase(updatePlace, (state, action) => {
        state.donationErrors[action.payload.place.id] = null
      })
  },
})

export const {setSeatingError, setPlaceDonationError, setPlaceDonationErrors} = errorsSlice.actions
export default errorsSlice.reducer
