import {createAsyncAction} from '../../services/redux-toolkit'
import {checkout} from '../checkout'
import {validateAllDonations} from './validation'

export const seatingCheckout = createAsyncAction('SEATING_CHECKOUT', async (_, {dispatch, rejectWithValue}) => {
  const valid = await dispatch(validateAllDonations()).unwrap()

  if (!valid) {
    return rejectWithValue({error: 'Donations not valid'})
  }

  dispatch(checkout())
})
