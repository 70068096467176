import {CouponErrorCode, hasCouponDiscount, hasPaidPlanDiscount} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {getDiscount} from '../actions/checkout'
import {RESET_COUPON_CODE, SUBMIT_COUPON} from '../actions/coupon'
import {CLEAR_INVOICE} from '../actions/invoice'
import {InvoiceState} from '../types'

export const defaultInvoiceState: InvoiceState = {
  error: null,
  invoice: null,
  coupon: {
    code: undefined,
    submitted: false,
    ticketId: null,
    validating: false,
  },
}

export const invoice = (state = defaultInvoiceState, action: AnyAction): InvoiceState => {
  switch (action.type) {
    case getDiscount.fulfilled.toString():
      return getDiscountSuccess(state, action)
    case getDiscount.rejected.toString():
      return getDiscountFailure(state)
    case SUBMIT_COUPON:
      return submitCoupon(state, action)
    case RESET_COUPON_CODE:
    case CLEAR_INVOICE:
      return defaultInvoiceState
    default:
      return state
  }
}

const getDiscountSuccess = (state: InvoiceState, action: AnyAction) => {
  if (getDiscount.fulfilled.match(action)) {
    const {invoice: returnedInvoice, discountErrors} = action.payload
    const error = getDisplayedErrorType(discountErrors)

    if (error) {
      return {
        ...state,
        error,
        coupon: {
          ...state.coupon,
          submitted: false,
          ticketId: null,
          validating: false,
        },
      }
    }

    const withCouponDiscount = hasCouponDiscount(returnedInvoice)
    const withPaidPlanDiscount = hasPaidPlanDiscount(returnedInvoice)

    if (withCouponDiscount || withPaidPlanDiscount) {
      const coupon = withCouponDiscount
        ? {
            ...state.coupon,
            submitted: true,
            ticketId: gatherCouponTicketId(returnedInvoice.items),
            validating: false,
          }
        : state.coupon

      return {
        ...state,
        error,
        invoice: returnedInvoice,
        coupon,
      }
    }

    if (state.coupon.submitted) {
      return {
        ...state,
        error: CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST,
        invoice: returnedInvoice,
        coupon: {
          ...state.coupon,
          submitted: false,
          ticketId: null,
          validating: false,
        },
      }
    }

    return {
      ...state,
      error,
      invoice: returnedInvoice,
    }
  }
}

const getDiscountFailure = (state: InvoiceState) => ({
  ...state,
  error: CouponErrorCode.CUSTOM_ERROR_TOO_BUSY,
  coupon: {
    ...state.coupon,
    submitted: false,
    ticketId: null,
    validating: false,
  },
})

const submitCoupon = (state: InvoiceState, action: AnyAction) => ({
  ...state,
  coupon: {
    ...state.coupon,
    code: action.payload,
    submitted: true,
    validating: true,
  },
})

const getDisplayedErrorType = (discountErrors: wix.events.ticketing.DiscountErrors | null) => {
  const hasError = discountErrors && discountErrors.error && discountErrors.error.length

  if (!hasError) {
    return null
  }

  const errorCode = discountErrors.error[0].code

  switch (errorCode) {
    case CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST:
    case CouponErrorCode.ERROR_COUPON_HAS_EXPIRED:
    case CouponErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET:
    case CouponErrorCode.ERROR_COUPON_USAGE_EXCEEDED:
    case CouponErrorCode.CUSTOM_ERROR_TOO_BUSY:
      return errorCode
    case CouponErrorCode.ERROR_COUPON_IS_DISABLED:
      return CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST
    default:
      return CouponErrorCode.CUSTOM_ERROR_UNKNOWN
  }
}

const gatherCouponTicketId = (items: wix.events.ticketing.Item[]) => {
  const itemsWithDiscount = items.filter(item => item.discount)

  if (items.length !== itemsWithDiscount.length && itemsWithDiscount.length > 0) {
    return itemsWithDiscount[0].id
  }

  return null
}
