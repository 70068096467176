import {updateSiteSettings} from '../../../../commons/actions/site-settings'

enum SettingsActions {
  UPDATE_COMP_SETTINGS = 'UPDATE_COMP_SETTINGS',
  SITE_SETTINGS = 'SITE_SETTINGS',
}

export const updateSettings = (action: any) => async (dispatch: Function) => {
  switch (action.type) {
    case SettingsActions.SITE_SETTINGS: {
      dispatch(updateSiteSettings(action.settings))
      break
    }
    default: {
      return
    }
  }
}
