import {createReducer} from '@reduxjs/toolkit'
import {CHANGE_EVENT, GET_EVENT, GET_MEMBERS} from '../actions/event'
import {EventState} from '../types'

const defaultState: EventState = {
  event: null,
  richContent: undefined,
}

export const event = createReducer(defaultState, builder => {
  builder
    .addCase(CHANGE_EVENT, (state, action) => {
      // @ts-expect-error
      state.event = action.payload.event
      // @ts-expect-error
      state.richContent = action.payload.richContent
    })
    .addCase(GET_EVENT.SUCCESS, (state, action) => {
      // @ts-expect-error
      state.event = action.payload
    })
    .addCase(GET_MEMBERS.SUCCESS, (state, action) => {
      // @ts-expect-error
      state.event.members = action.payload.members
      // @ts-expect-error
      state.event.totalGuests = action.payload.totalGuests
    })
})
