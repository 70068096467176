import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {ControllerFlowAPI, IWixAPI} from '@wix/yoshi-flow-editor'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {ReservationState} from '../../../../commons/enums'
import {isEditor, isPreview} from '../../../../commons/utils/wix-code-api'
import {EventSlugQueryOverrideParam} from '../constants'
import {Navigation, RouteParams, ThankYouMessageState} from '../types'

const getParts = (wixCodeApi: IWixAPI): string[] => {
  const {baseUrl, url, path} = wixCodeApi.location
  // fallback for weirder cases
  if (!baseUrl || !url.includes(baseUrl)) {
    if (!url.includes(baseUrl) && url.includes('document') && isPreview(wixCodeApi)) {
      return path.slice(path.indexOf('document') + 2)
    }

    if (isEditor(wixCodeApi)) {
      return path
    }

    return path.slice(1)
  }

  const strippedUrl = url.split('?')[0].split('#')[0]
  const route = strippedUrl.replace(new RegExp(`${baseUrl}\\/?`), '').split('/')
  return route
}

export const getRouteParams = (wixCodeApi: IWixAPI): RouteParams => {
  const path = getParts(wixCodeApi)
  switch (getRoute(wixCodeApi)) {
    case DETAILS_ROUTE.ORDER:
      return {
        reservationId: path[3],
        reservationState: (path[4] ?? wixCodeApi.location.query.status) as ReservationState,
      }
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return {
        thankYouMessageState: path[3] as ThankYouMessageState,
      }
    case DETAILS_ROUTE.ONLINE_CONFERENCING: {
      const conferencingState = path[3]
      if (
        conferencingState === DETAILS_ROUTE.ONLINE_CONFERENCING_DISABLED ||
        conferencingState === DETAILS_ROUTE.ONLINE_CONFERENCING_PROVIDER_ERROR
      ) {
        return {conferencingState}
      } else {
        return {}
      }
    }
    default:
      return {}
  }
}

export const getRoute = (wixCodeApi: IWixAPI) => {
  const path = getParts(wixCodeApi)
  return (path[2] || DETAILS_ROUTE.DETAILS) as DETAILS_ROUTE
}

export const parseLocation = (wixCodeApi: IWixAPI, flowAPI: ControllerFlowAPI): Navigation => {
  const path = getParts(wixCodeApi)
  const {query} = wixCodeApi.location

  return {
    sectionPath: getSectionPath(wixCodeApi, path),
    slug: getSlug(wixCodeApi, path, flowAPI),
    route: getRoute(wixCodeApi),
    ...getRouteParams(wixCodeApi),
    shouldNavigateBack: false,
    query,
  }
}

const getSectionPath = (wixCodeApi: IWixAPI, path: string[]) => {
  if (isPreview(wixCodeApi) && path?.length === 1) {
    return ''
  }

  return path[0] || ''
}

const getSlug = (wixCodeApi: IWixAPI, path: string[], flowAPI: ControllerFlowAPI) => {
  if (isEditor(wixCodeApi)) {
    if (flowAPI.experiments.enabled(ExperimentNames.SausageIntegration)) {
      return path[1] ?? ''
    } else {
      return wixCodeApi.location.query?.[EventSlugQueryOverrideParam] ?? ''
    }
  }
  if (isPreview(wixCodeApi) && path?.length === 1) {
    return path[0]
  }

  return path[1] || ''
}

export const isReservationIdFromQuery = (wixCodeApi: IWixAPI) => {
  return Boolean(wixCodeApi.location?.query?.reservationId)
}
