import {createAction} from '@reduxjs/toolkit'
import {hasSeatingPlan, isDraft} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {isMobile} from '../../../../commons/selectors/environment'
import {isTemplate} from '../../../../commons/selectors/instance'
import {UserRole} from '../constants/constants'
import {isUpgradeNeededForCheckout} from '../selectors/checkout-options'
import {getEvent} from '../selectors/event'
import {isEventPreview} from '../selectors/navigation'
import {isRequestPending} from '../selectors/pending-requests'
import {getSeatsReservationQuantities} from '../selectors/seating/places'
import {getReservationQuantities} from '../selectors/selected-tickets'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState, StoreExtraArgs} from '../types'
import {Interaction} from '../utils/interactions'
import {openCheckoutUnavailable, openUpgradeToPremium} from './modals'
import {NAVIGATE, navigateToMobileCheckout, navigateToTicketForm} from './navigation'

export const RESERVE_TICKETS = createActions('RESERVE_TICKETS')

export const reserveTickets =
  (eventId: string) =>
  async (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const state = getState()
    const upgradeNeeded = isUpgradeNeededForCheckout(state)
    const owner = wixCodeApi.user.currentUser.role === UserRole.ADMIN
    const event = getEvent(state)

    if (isTemplate(state) || isEventPreview(state) || isDraft(event)) {
      return isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
    }

    if (upgradeNeeded && (isMobile(state) || !owner)) {
      return dispatch(openCheckoutUnavailable())
    }

    const ticketQuantities: wix.events.ticketing.TicketReservationQuantity[] = hasSeatingPlan(event)
      ? getSeatsReservationQuantities(state)
      : getReservationQuantities(state)

    if (upgradeNeeded && owner) {
      const {proceed} = await dispatch(openUpgradeToPremium()).unwrap()
      if (!proceed) {
        return
      }
    }

    return dispatch(makeReservation(eventId, ticketQuantities))
  }

interface CancelReservationParams {
  eventId: string
  reservationId: string
}

export const cancelReservation = createAsyncAction<{}, CancelReservationParams>(
  'CANCEL_RESERVATION',
  ({eventId, reservationId}, {extra: {serverApi}}) => {
    if (eventId && reservationId) {
      return serverApi.cancelReservation(eventId, reservationId)
    }
  },
)

export const makeReservation =
  (eventId: string, ticketQuantities: wix.events.ticketing.TicketReservationQuantity[]) =>
  async (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    const state = getState()

    if (!isRequestPending(state, NAVIGATE.REQUEST) && !isRequestPending(state, RESERVE_TICKETS.REQUEST)) {
      flowAPI.fedops.interactionStarted(Interaction.CreateReservation)

      if (!state.demoTickets?.length) {
        await dispatch(callAPI(RESERVE_TICKETS, eventId, ticketQuantities))
      } else {
        dispatch({
          type: RESERVE_TICKETS.SUCCESS,
          payload: {
            id: 'demo',
            reservations: [],
            expires: new Date(new Date().getTime() + 20 * 60 * 1000).toISOString(),
          },
        })
      }

      isMobile(state) ? dispatch(navigateToMobileCheckout()) : dispatch(navigateToTicketForm())
    }
  }

export const clearReservationError = createAction<boolean>('CLEAR_RESERVATION_ERROR')
