import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {SLOT_EVENT_DETAILS_CONTENT, SLOT_EVENT_DETAILS_HEADER} from '../../../commons/constants/slots'
import {getEvent} from '../Widget/selectors/event'
import {State} from '../Widget/types'

export const setSlotData = (controllerParams: ControllerParams, state: State) => {
  const event = getEvent(state)
  const {flowAPI} = controllerParams
  if (flowAPI.experiments.enabled(ExperimentNames.EventDetailsSlotsViewer)) {
    if (event?.id) {
      try {
        const slotAPIFactory = createSlotVeloAPIFactory(controllerParams.controllerConfig)

        for (const slotId of [SLOT_EVENT_DETAILS_HEADER, SLOT_EVENT_DETAILS_CONTENT]) {
          const slot = slotAPIFactory.getSlotAPI(slotId)
          slot.eventId = event.id
        }
      } catch (e) {
        console.log(e)
        flowAPI.reportError(e)
      }
    }
  }
}
