import {AnyAction} from 'redux'
import {GET_CONNECTED_GROUP, GET_GROUP_ACTIVITY} from '../actions/groups'
import {GroupsState} from '../types'

const defaultState: GroupsState = {
  group: undefined,
  updates: undefined,
}

export const groups = (state = defaultState, action: AnyAction): GroupsState => {
  switch (action.type) {
    case GET_CONNECTED_GROUP.SUCCESS:
      return {
        ...state,
        group: action.payload?.group,
      }
    case GET_GROUP_ACTIVITY.SUCCESS:
      return {
        ...state,
        updates: action.payload.totalPosts,
      }
    default:
      return state
  }
}
