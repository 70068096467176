import {INPUT_NAMES} from '@wix/wix-events-commons-statics'
import {FormStep} from '../constants'
import {RegFormData, State} from '../types'
import {getEvent} from './event'

export const shouldNavigateToForm = (state: State) => {
  const eventControls = state ? getEvent(state)?.form?.controls : undefined
  const rsvpControls = state?.memberRsvp?.rsvp?.rsvpForm?.inputValues

  if (eventControls && rsvpControls) {
    const filteredEventControls = eventControls
      .filter(({name}) => name !== INPUT_NAMES.NAME && name !== INPUT_NAMES.EMAIL)
      .map(({name}) => name)
      .sort()
    const filteredRsvpControls = rsvpControls
      .filter(
        ({inputName}) =>
          inputName !== INPUT_NAMES.EMAIL &&
          inputName !== INPUT_NAMES.FIRST_NAME &&
          inputName !== INPUT_NAMES.LAST_NAME &&
          inputName !== INPUT_NAMES.GUEST_NAMES,
      )
      .map(({inputName}) => inputName)
      .sort()
    const eventControlsContainsAllRsvpControls = filteredEventControls.every(val => filteredRsvpControls.includes(val))

    return filteredEventControls.length < filteredRsvpControls.length || !eventControlsContainsAllRsvpControls
  } else {
    return false
  }
}

export const isStepVisible = ({step, hasPolicies}: IsStepVisibleArgs) => {
  switch (step) {
    case FormStep.Policies:
      return hasPolicies
    default:
      return true
  }
}

export const isStepCompleted = ({step, rsvpDetails, agreedWithPolicies}: IsStepCompletedArgs) => {
  switch (step) {
    case FormStep.BuyerDetails:
      return Boolean(rsvpDetails)
    case FormStep.Policies:
      return agreedWithPolicies
    default:
      return false
  }
}

export const getCurrentStep = (state: State) => state.rsvp.currentStep

export const getRsvpDetails = (state: State) => state.rsvp.rsvpDetails

export const getResponse = (state: State) => state.rsvp.response

interface IsStepVisibleArgs {
  step: FormStep
  hasPolicies: boolean
}

interface IsStepCompletedArgs {
  step: FormStep
  rsvpDetails: RegFormData
  agreedWithPolicies: boolean
}
