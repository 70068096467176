import {AnyAction} from 'redux'
import {getOrder} from '../actions/placed-order'
import {SEND_RSVP} from '../actions/rsvp'

export const calendarLinks = (state = null, action: AnyAction) => {
  switch (action.type) {
    case SEND_RSVP.SUCCESS:
    case getOrder.fulfilled.toString():
      return {
        ...action.payload.calendarLinks,
      }
    default:
      return state
  }
}
