import {AnyAction} from 'redux'
import {SET_INSTANCE} from '../actions/instance'
import {decodeInstance} from '../selectors/instance'
import {InstanceState} from '../types/state'

const defaultState: InstanceState = {
  instance: null,
  visitorId: null,
  msid: null,
  instanceId: null,
  siteIsTemplate: false,
  demoMode: false,
  siteOwnerId: null,
}

export const instance = (state = defaultState, action: AnyAction): InstanceState => {
  switch (action.type) {
    case SET_INSTANCE:
      return {
        instance: action.payload.instance,
        ...decodeInstance(action.payload.instance),
      }
    default:
      return state
  }
}
