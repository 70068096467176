import {updateDates} from '../../../../commons/actions/dates'
import {PLACE_ORDER, UPDATE_ORDER, getOrder} from '../actions/placed-order'

export const datesMiddleware = store => next => action => {
  switch (action.type) {
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case getOrder.fulfilled.toString(): {
      store.dispatch(updateDates(action.payload.dates))
      break
    }
    default:
      break
  }
  next(action)
}
