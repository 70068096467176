import {createSlice} from '@reduxjs/toolkit'
import {checkout} from '../../actions/checkout'
import {closeNativeModal} from '../../actions/modals'
import {setSeatingError} from './errors'

const initialState = false

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(checkout.pending, () => true)
      .addCase(checkout.fulfilled, () => false)
      .addCase(checkout.rejected, () => false)
      .addCase(closeNativeModal, () => false)
      .addCase(setSeatingError, () => false)
  },
})

export default loadingSlice.reducer
