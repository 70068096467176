import {AnyAction} from 'redux'
import {FETCH_CURRENT_MEMBER} from '../actions/members'
import {CurrentMemberDetails} from '../types'

const defaultState: CurrentMemberDetails = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
}

export const currentMemberDetails = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_CURRENT_MEMBER.SUCCESS:
      const member = action.payload
      return {
        ...state,
        id: member?.id,
        email: member?.loginEmail,
        firstName: member?.firstName,
        lastName: member?.lastName,
      }
    default:
      return state
  }
}
