import {createAction} from '@reduxjs/toolkit'
import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {ModalResponse} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {
  getHomePageUrl,
  hasHeadlessOriginUrl,
  isEditor,
  isHeadless,
  isMobile,
} from '../../../../commons/selectors/environment'
import {isTemplate} from '../../../../commons/selectors/instance'
import {openDialogModal, openModal} from '../../../../commons/services/modal'
import {NativeModal} from '../components/modals/constants'
import {getEvent} from '../selectors/event'
import {isEventPreview} from '../selectors/navigation'
import {getReservationId} from '../selectors/reservation'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState, ModalsPayload, StoreExtraArgs} from '../types'
import {isReservationIdFromQuery} from '../utils/navigation'
import {changeEvent} from './event'
import {navigateBack, navigateToDetails, navigateToHomePage, navigateToTicketsPicker} from './navigation'
import {cancelReservation} from './reservation'
import {describeSeatingPlan} from './seating/plan'

export const openCheckoutUnavailable = createAsyncAction(
  'OPEN_CHECKOUT_UNAVAILABLE',
  (
    _,
    {
      dispatch,
      getState,
      extra: {
        wixCodeApi,
        flowAPI: {
          translations: {t},
        },
      },
    },
  ) => {
    const state = getState()
    const headless = isHeadless(state)
    const homePageUrl = getHomePageUrl(state)

    return dispatch(
      openDialogModal({
        type: 'checkout-unavailable',
        params: {
          buttonText: headless && homePageUrl ? t('backToSite') : undefined,
        },
        onClose: () => {
          if (headless) {
            if (homePageUrl) {
              wixCodeApi.location.to(homePageUrl)
            }
          } else {
            // reload
            wixCodeApi.location.to(wixCodeApi.location.url)
          }
        },
      }),
    )
  },
)

export const openUpgradeToPremium = createAsyncAction<{proceed: boolean}, void>(
  'OPEN_UPGRADE_TO_PREMIUM',
  (_, {dispatch}) => {
    return new Promise(resolve => {
      dispatch(
        openDialogModal({
          type: 'upgrade-to-premium',
          onClose: (options: {message: {proceed?: any}}) => {
            if (options.message.proceed) {
              return resolve({proceed: true})
            }
            return resolve({proceed: false})
          },
        }),
      )
    })
  },
)

export const openHeadlessErrorModal = (message: string) =>
  openNativeModal({type: NativeModal.HEADLESS_ERROR, payload: {message}})

export const openTicketsDetailsModal = () => openNativeModal({type: NativeModal.ORDER_SUMMARY})

export const openTicketsDownloadModal = () => openNativeModal({type: NativeModal.TICKETS_DOWNLOAD})

export const openSeatingPlan = () => async (dispatch: Function) => {
  await dispatch(describeSeatingPlan())
  dispatch(openNativeModal({type: NativeModal.SEATING_PLAN}))
}

export const openConfirmRsvp = () => openNativeModal({type: NativeModal.CONFIRM_RSVP})

export const openTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isTemplate(state) || isEventPreview(state) || isEditor(state)) {
    return null
  }

  return dispatch(
    openDialogModal({
      type: 'time-expired',
      onClose: () => dispatch(onCloseTimeExpiredModal()),
    }),
  )
}

const onCloseTimeExpiredModal = () => (dispatch: Function, getState: GetState, extra: StoreExtraArgs) => {
  const state = getState()
  const eventId = getEvent(state).id
  const reservationId = getReservationId(state)

  dispatch(cancelReservation({eventId, reservationId}))

  if (isHeadless(state)) {
    if (hasHeadlessOriginUrl(state)) {
      return dispatch(navigateToHomePage())
    }
    return
  }

  if (isReservationIdFromQuery(extra.wixCodeApi)) {
    return dispatch(navigateBack())
  }

  if (isMobile(state)) {
    return dispatch(navigateToTicketsPicker())
  }

  return dispatch(navigateToDetails())
}

export const openCantCompletePaymentModal = () =>
  openDialogModal({
    type: 'cant-complete-payment',
  })

export const openRecurringEventsModal = (eventId: string) => async (dispatch: Function) => {
  const response: ModalResponse = await dispatch(openModal({type: 'recurring-events', params: {eventId}}))
  if (response.message.slug) {
    dispatch(changeEvent(response.message.slug))
  }
  dispatch(setActiveElement(hookToAttributeSelector(DH.RECURRING_DATES_BUTTON)))
}

export const openNativeModal = <T extends NativeModal>({type, payload}: {type: T; payload?: ModalsPayload<T>}) =>
  _openNativeModal({type, payload})
export const _openNativeModal = createAction<{type: NativeModal; payload?: ModalsPayload<NativeModal>}>(
  'OPEN_NATIVE_MODAL',
)

export const closeNativeModal = createAction('CLOSE_NATIVE_MODAL')
