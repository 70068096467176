import {getEvent} from '../selectors/event'
import {createAsyncAction} from '../services/redux-toolkit'

export const getStaticMapUrl = createAsyncAction<string, string>(
  'GET_STATIC_MAP_URL',
  async (language, {getState, extra: {serverApi}}) => {
    const event = getEvent(getState())
    return serverApi.getStaticMapUrl(event.id, language)
  },
)
